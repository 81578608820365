/* Wrapper for the whole page */
.homepage-wrapper {
  position: relative;
  height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Full-page blurred background */
.blurred-background {
  position: fixed; /* Full-page static background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../asset/Images/hero.png') center/cover no-repeat; /* Use your desired background image */
  filter: blur(10px); /* Adjust blur intensity */
  z-index: -1; /* Place it behind the content */
}

/* Content of the page */
.homepage-content {
  position: relative;
  z-index: 1; /* Keep above the blurred background */
}

.icon-circle {
  background-color: #ba9131;
}

.hero-background-image {
  width: 100%;
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 20vw;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  animation: softFadeScale 1.2s ease-out;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color:#00A0C6; 
  text-decoration:none; 
  cursor:pointer;  
}

@keyframes softFadeScale {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .hero-background-image {
      display: none;
  }

  .banner-content {
      position: relative;
      text-align: center;
      padding: 50px 20px;
      color: white;
  }

  .banner-content::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('../asset/Images/hero.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(0.5); /* Adjust brightness here */
      z-index: -1;
      border-radius: 20px;
  }

  .banner-content h1 {
      font-size: 36px;
  }
}



.banner-content h1{ 
    font-weight: 600;
    line-height: 1.2;
    
}

.banner-content p { 
    margin-bottom: 0;
    line-height: 1.7;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes subtleFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}

  .icon-circle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .bg-pink {
    background-color: #ff007f; /* Pink color similar to the image */
  }
  
  .border-left {
    border-left: 1px solid #ddd;
  }
  
  .border-right {
    border-right: 1px solid #ddd;
  }


  /**/
.banner-section h2 {
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
}
.ethos-section .text-pink {
  color: #ba9131; /* Matches the pink color in the design */
}

.ethos-card {
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid rgb(218, 218, 218);
  width: 100%;
  height: 100%;
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-circle {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}


.text-pink { 
  color: #ba9131
}




.popular-dishes-section .text-pink {
  color: #ba9131;
}

.dish-card {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sushi-image { 
  animation: fadeInScale 1s ease-in-out;

}

.dish-card:hover {
  transform: translateY(-5px);
}

.dish-image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.btn-pink {
  background-color: #ba9131;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.btn-pink:hover {
  background-color: #b18e3d;
}




/* Main container background */
.app-wrapper {
  background-color: #ba9131;
  border-radius: 20px;
  padding: 40px 20px;
  color: white;
  border-top-left-radius: 4vw;
  border-bottom-right-radius: 4vw;
  
}

.app-container {
  max-width: 1200px;
}

.app-heading {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.app-paragraph {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.store-badge {
  width: 150px;
}

.app-photo {
  position: relative;
  width: 180px;
}

.one {
  z-index: 3;
  
}

.two {
  z-index: 2;
}

.three {
  z-index: 1;
}






.simple-slider {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
}

.slide {
  padding: 20px;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dish-image {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 20px;
}



.profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ddd;
}







.footer {
  padding: 40px 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  color: #333;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px 20px;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.footer-logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.footer-brand {
  font-size: 1.2rem;
  font-weight: bold;
}

.footer p {
  font-size: 0.9rem;
  margin: 5px 0;
}

.license-info {
  display: inline-block;
  color: #ba9131;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 10px;
}

.license-info:hover {
  text-decoration: underline;
}

.footer-socials {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-size: 1.2rem;
}

.footer-socials svg {
  cursor: pointer;
  color: #ba9131;
  transition: color 0.3s;
}

.footer-socials svg:hover {
  color: #bb9846;
}

.opening-times {
  list-style-type: none;
  padding: 0;
  font-size: 0.9rem;
}

.opening-times li {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.opening-times strong {
  font-weight: bold;
}

.footer-section h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 0.9rem;
  margin: 5px 0;
}
