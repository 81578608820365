.header {
  transition: top 0.3s ease-in-out;
}

.header.hidden {
  top: -100px; /* Hide the header when scrolling down */
}

.header.visible {
  top: 0; /* Show the header when scrolling up */
}

.header-logo img {
  width: 90px;
  cursor: pointer;
}

.order-now-button {
  font-weight: bold;
  color: #fff;
  border: 2px solid #ba9131;
}

.order-now-button:hover {
  background-color: #ba9131;
  color: #fff;
}
